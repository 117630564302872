<script setup>
  import { User } from '~/src/account/user'
  import { userStore } from '~/stores/user'

  const store = userStore()
  const email = ref('')
  const password = ref('')
  const msg = ref('')
  const user = new User()
  const loading = ref(false)

  onMounted(async () => {
    if (store.user)
      await navigateTo({ name: 'index' })
  })

  async function login() {
    loading.value = true
    setTimeout(() => {
      loading.value = false
    }, 3000);
    const res = await user.login(email.value, password.value)
    if (res && res.error) {
      msg.value = 'The credentials you\'ve entered are incorrect. Please try again.'
      loading.value = false
    }
  }
</script>

<template>
  <div class="w-full h-full flex flex-col items-center justify-center overflow-y-scroll">
    <div class="w-1/4 border rounded-3xl bg-white px-8 py-12 pt-4">
      <form @submit.prevent="login">
        <div class="">
          <div>
            <h1 class="text-xl font-bold text-start">
              Welcome back
            </h1>
            <p class="text-start text-sm mt-1">
              Don't have an account? <a class="font-normal text-violet-700" href="/register">Sign up now.</a>
            </p>
          </div>
          <div class="mt-8">
            <label for="email" class="block mb-2 text-sm text-[#a7a0b8]">
              Email
            </label>
            <input
              id="email" v-model="email" type="email" name="email"
              placeholder="you@company.com"
              class="w-full shadow-lg px-3 py-2 mb-6 rounded-lg"
            >
          </div>
          <div>
            <label for="password" class="text-sm text-[#a7a0b8]">Password</label>
            <input 
              id="password" v-model="password" type="password" name="password" placeholder="Password" 
              class="w-full shadow-lg px-3 py-2 mb-6 rounded-lg"
            >
          </div>
          <div>
            <p v-if="msg" :key="msg" class="pl-0 text-left text-sm text-red-500">
              {{ msg }}
            </p>
          </div>
          <div class="flex justify-end text-violet-700">
            <a href="/forgot-password" class="text-xs">Forgot password?</a>
          </div>
          <button
            type="submit" :disabled="loading.value" 
            class="flex text-center justify-center items-center w-full px-8 mt-6 py-2 text-white font-semibold bg-mythos-violet rounded-xl text-[1rem] min-w-[150px] self-center"
          >
            <svg v-if="loading" width="18" height="18" class="mr-2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <circle class="spinner_S1WN" cx="4" cy="12" r="3" fill="#ddd" />
              <circle class="spinner_S1WN spinner_Km9P" cx="12" cy="12" r="3" fill="#ddd" />
              <circle class="spinner_S1WN spinner_JApP" cx="20" cy="12" r="3" fill="#ddd" />
            </svg>

            <span v-else>Login</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
